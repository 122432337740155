/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const removeUndefinedProps = (obj: any) => {
  for (const prop in obj) {
    if (
      obj.hasOwnProperty(prop) &&
      (obj[prop] === undefined || obj[prop] === "")
    ) {
      delete obj[prop];
    }
  }

  return obj;
};

export const paddNumber = (num: number): string => {
  return num < 10 ? `0${num}` : `${num}`;
};
