





























import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";

import LineChart from "../chart/LineChart.vue";

import clientStoreModule from "@/store/modules/client";
import { Business, Role } from "@/types";
import { paddNumber } from "@/util/helpers";

const { mapActions: clientActions, mapGetters: clientGetters } =
  createNamespacedHelpers("CLIENTS_AGGREGATE");

const {
  mapActions: clientWeekAggregateActions,
  mapGetters: clientWeekAggregateGetters,
} = createNamespacedHelpers("CLIENTS_LAST_WEEK_AGGREGATE");

export default Vue.extend({
  components: { LineChart },
  name: "ClientGraph",
  props: {
    periodOverride: {
      type: String,
      required: false,
    },
    dateOverride: {
      type: Object as PropType<{
        label: string;
        start: string;
        end: string;
        period: string;
      }>,
      required: false,
    },
  },
  data: () => ({
    period: "Day",
    days: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    years: [
      2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033,
      2034, 2035,
    ],
    chartData: {
      labels: [] as number[] | string[],
      datasets: [
        {
          label: "Yesterday",
          backgroundColor: "#FDF3E9",
          data: [] as number[],
          pointRadius: 0,
          borderColor: "#F48203",
          pointStyle: "line",
        },
        {
          label: "Today",
          backgroundColor: "#FCFCFE",
          data: [] as number[],
          pointRadius: 0,
          borderColor: "#DFE0EA",
          pointStyle: "line",
        },
      ],
    },
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: "top",
        align: "start",
        labels: {
          usePointStyle: true,
        },
      },
      tooltips: {
        titleFontSize: 20,
        bodyFontSize: 25,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: false,
              callback: (value: number) => {
                if (value % 1 === 0) {
                  return value;
                }
              },
            },
            position: "right",
          },
        ],
      },
    },
    overridePeriod: false,
    weeks: Array.from({ length: 52 }, (_, i) => paddNumber(i + 1)),
  }),
  created() {
    this.getAggregate();
  },
  watch: {
    period() {
      if (!this.overridePeriod) this.getAggregate();
    },
    role() {
      this.getAggregate();
    },
    periodOverride() {
      if (this.periodOverride) this.period = this.periodOverride;
    },
    dateOverride() {
      if (this.dateOverride.start) {
        this.overridePeriod = true;
        this.period = this.dateOverride.period;

        if (!this.role) return;
        const businessId = (this.role.business as Business)._id;

        const { start, end, period } = this.dateOverride;
        let params = `?businessId=${businessId}`;
        //prevPeriodParams = params;
        if (period !== "All")
          params += `&startDate=${start}&endDate=${end}&period=${period.toLowerCase()}`;
        this.fetchClientAggregate(params).then((agg) => {
          if (agg) {
            this.setChartData(period.toLowerCase());
          }
          this.overridePeriod = false;
        });
        return;
      }
    },
  },
  computed: {
    ...clientGetters(["clientAggregate"]),
    ...clientWeekAggregateGetters({
      prevPeriodClientAggregate: "clientAggregate",
    }),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...clientActions(["fetchClientAggregate"]),
    ...clientWeekAggregateActions({
      fetchPrevPeriodClientAggregate: "fetchClientAggregate",
    }),

    getAggregate() {
      if (!this.role) return;
      let prevPeriodParams = "";
      const businessId = (this.role.business as Business)._id;
      let params = "";
      let period = this.period.toLowerCase() as
        | "year"
        | "month"
        | "week"
        | "day"
        | "all";

      if (period === "all") {
        period = "year";
      }
      if (period === "year") {
        params = `?period=${period}`;
        prevPeriodParams = params;
      } else if (period === "month") {
        const startDate = moment().startOf("year").toISOString();
        const endDate = moment().endOf("year").toISOString();
        params = `?startDate=${startDate}&endDate=${endDate}&period=${period}`;

        const prevStartDate = moment()
          .subtract(1, "year")
          .startOf("year")
          .toISOString();
        const prevEndDate = moment()
          .subtract(1, "year")
          .endOf("year")
          .toISOString();

        prevPeriodParams = `?startDate=${prevStartDate}&endDate=${prevEndDate}&period=${period}`;
      } else if (period === "week") {
        let startDate = moment().startOf("year").toISOString();
        let endDate = moment().endOf("year").toISOString();
        params = `?startDate=${startDate}&endDate=${endDate}&period=${period}`;

        startDate = moment().subtract(1, "year").startOf("year").toISOString();
        endDate = moment().subtract(1, "year").endOf("year").toISOString();
        prevPeriodParams = `?startDate=${startDate}&endDate=${endDate}&period=${period}`;
      } else if (period === "day") {
        const startDate = moment().startOf("week").toISOString();
        const endDate = moment().endOf("week").toISOString();
        params = `?startDate=${startDate}&endDate=${endDate}&period=${period}`;

        const prevStartDate = moment()
          .subtract(1, "week")
          .startOf("week")
          .toISOString();
        const prevEndDate = moment()
          .subtract(1, "week")
          .endOf("week")
          .toISOString();

        prevPeriodParams = `?startDate=${prevStartDate}&endDate=${prevEndDate}&period=${period}`;
      }
      if (params === "") params = `?businessId=${businessId}&period=${period}`;
      else params += `&businessId=${businessId}`;
      this.fetchClientAggregate(params).then((aggregate) => {
        if (aggregate) {
          if (prevPeriodParams === "")
            prevPeriodParams = `?businessId=${businessId}&period=${period}`;
          else prevPeriodParams += `&businessId=${businessId}`;
          this.fetchPrevPeriodClientAggregate(prevPeriodParams).then(
            (prevAggregate) => {
              if (prevAggregate) this.setChartData(period);
            }
          );
        }
      });
    },
    setChartData(period: string) {
      if (period === "day") {
        const data = {
          Sun: 0,
          Mon: 0,
          Tue: 0,
          Wed: 0,
          Thu: 0,
          Fri: 0,
          Sat: 0,
        };

        this.clientAggregate.map((a: { _id: number; count: number }) => {
          const indx = this.days[a._id - 1] as "Sun" | "Mon";
          data[indx] = a.count;
        });

        const prevPeriodData = {
          Sun: 0,
          Mon: 0,
          Tue: 0,
          Wed: 0,
          Thu: 0,
          Fri: 0,
          Sat: 0,
        };

        this.prevPeriodClientAggregate.map(
          (a: { _id: number; count: number }) => {
            const indx = this.days[a._id - 1] as "Sun" | "Mon";
            prevPeriodData[indx] = a.count;
          }
        );

        this.chartData = {
          labels: this.days,
          datasets: [
            {
              label: "Current Week",
              backgroundColor: "#FDF3E9",
              data: Object.values(data),
              pointRadius: 0,
              borderColor: "#F48203",
              pointStyle: "line",
            },
            {
              label: "Previous Week",
              backgroundColor: "#FCFCFE",
              data: Object.values(prevPeriodData),
              pointRadius: 0,
              borderColor: "#DFE0EA",
              pointStyle: "line",
            },
          ],
        };
        return;
      }

      if (period === "week") {
        const data = {};

        const prevPeriodData = {};

        this.weeks.map((w) => {
          data[w] = prevPeriodData[w] = 0;
        });

        this.clientAggregate.map((a: { _id: number; count: number }) => {
          data[a._id] = a.count;
        });

        this.prevPeriodClientAggregate.map(
          (a: { _id: number; count: number }) => {
            prevPeriodData[a._id] = a.count;
          }
        );

        this.chartData = {
          labels: this.weeks,
          datasets: [
            {
              label: "Current Year",
              backgroundColor: "#FDF3E9",
              data: Object.values(data),
              pointRadius: 0,
              borderColor: "#F48203",
              pointStyle: "line",
            },
            {
              label: "Previous Year",
              backgroundColor: "#FCFCFE",
              data: Object.values(prevPeriodData),
              pointRadius: 0,
              borderColor: "#DFE0EA",
              pointStyle: "line",
            },
          ],
        };
        return;
      }

      if (period === "month") {
        const data = {
          Jan: 0,
          Feb: 0,
          Mar: 0,
          Apr: 0,
          May: 0,
          Jun: 0,
          Jul: 0,
          Aug: 0,
          Sep: 0,
          Oct: 0,
          Nov: 0,
          Dec: 0,
        };

        this.clientAggregate.map((a: { _id: number; count: number }) => {
          const indx = this.months[+a._id - 1] as "Jan" | "Feb";
          data[indx] = a.count;
        });

        const prevPeriodData = {
          Jan: 0,
          Feb: 0,
          Mar: 0,
          Apr: 0,
          May: 0,
          Jun: 0,
          Jul: 0,
          Aug: 0,
          Sep: 0,
          Oct: 0,
          Nov: 0,
          Dec: 0,
        };

        this.prevPeriodClientAggregate.map(
          (a: { _id: number; count: number }) => {
            const indx = this.months[+a._id - 1] as "Jan" | "Feb";
            prevPeriodData[indx] = a.count;
          }
        );

        this.chartData = {
          labels: Object.keys(data),
          datasets: [
            {
              label: "Current year",
              backgroundColor: "#FDF3E9",
              data: Object.values(data),
              pointRadius: 0,
              borderColor: "#F48203",
              pointStyle: "line",
            },
            {
              label: "Previous year",
              backgroundColor: "#FCFCFE",
              data: Object.values(prevPeriodData),
              pointRadius: 0,
              borderColor: "#DFE0EA",
              pointStyle: "line",
            },
          ],
        };
        return;
      }

      if (period === "year" || period === "all") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = {};

        const labels = this.years.filter((y) => new Date().getFullYear() >= +y);

        labels.map((l) => {
          data[l] = 0;
        });

        this.clientAggregate.map((a: { _id: string; count: number }) => {
          data[a._id] = a.count;
        });

        console.log("data: ", data);

        this.chartData = {
          labels: labels,
          datasets: [
            {
              label: "Over the Years",
              backgroundColor: "#FDF3E9",
              data: Object.values(data),
              pointRadius: 0,
              borderColor: "#F48203",
              pointStyle: "line",
            },
          ],
        };
        return;
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENTS_AGGREGATE")) {
      this.$store.registerModule("CLIENTS_AGGREGATE", clientStoreModule);
    }
    if (!this.$store.hasModule("CLIENTS_LAST_WEEK_AGGREGATE")) {
      this.$store.registerModule(
        "CLIENTS_LAST_WEEK_AGGREGATE",
        clientStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CLIENTS_LAST_WEEK_AGGREGATE");
    this.$store.unregisterModule("CLIENTS_AGGREGATE");
  },
});
