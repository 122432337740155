





































































































import Vue from "vue";

import DashboardCards from "@/components/dashboard/DashboardCards.vue";
import ClientGraph from "@/components/dashboard/ClientGraph.vue";
import ClientScheduleList from "@/components/dashboard/ClientScheduleList.vue";
import EmployeeList from "@/components/dashboard/EmployeeList.vue";
// import FacebookRetentionRateChart from "@/components/dashboard/FacebookRetentionRateChart.vue";
// import EmailOpenRateChart from "@/components/dashboard/EmailOpenRateChart.vue";
import StaffRevenueChart from "@/components/dashboard/StaffRevenueChart.vue";
import RevenueGraph from "@/components/dashboard/RevenueGraph.vue";
// import StaffAttendanceRateGraph from "@/components/dashboard/StaffAttendanceRateGraph.vue";
import ProductSalesGraph from "@/components/dashboard/ProductSalesGraph.vue";
// import InstagramRetantionRateChart from "@/components/dashboard/InstagramRetantionRateChart.vue";
// import SmsOpenRateChart from "@/components/dashboard/SmsOpenRateChart.vue";
// import AgizoShopRevenueGraph from "@/components/dashboard/AgizoShopRevenueGraph.vue";
// import GoogleAnalytics from "@/components/dashboard/GoogleAnalytics.vue";
// import Feedback from "@/components/dashboard/Feedback.vue";
// import NewReturningClientRateGraph from "@/components/dashboard/NewReturningClientRateGraph.vue";
import ActionButtons from "@/components/ActionButtons.vue";
import moment from "moment";

export default Vue.extend<any, any, any, any>({
  components: {
    DashboardCards,
    ClientGraph,
    ClientScheduleList,
    EmployeeList,
    // FacebookRetentionRateChart,
    // EmailOpenRateChart,
    StaffRevenueChart,
    RevenueGraph,
    //StaffAttendanceRateGraph,
    ProductSalesGraph,
    // InstagramRetantionRateChart,
    // SmsOpenRateChart,
    // AgizoShopRevenueGraph,
    // GoogleAnalytics,
    // Feedback,
    // NewReturningClientRateGraph,
    ActionButtons,
  },
  name: "Dashboard",
  data: () => ({
    period: "",
    date: {},
    filters: [
      {
        label: "Today",
        start: moment().startOf("day").toISOString(),
        end: moment().endOf("day").toISOString(),
        period: "Day",
      },
      {
        label: "Yesterday",
        start: moment().subtract(1, "day").startOf("day").toISOString(),
        end: moment().subtract(1, "day").endOf("day").toISOString(),
        period: "Day",
      },
      {
        label: "This week",
        start: moment().startOf("week").toISOString(),
        end: moment().endOf("week").toISOString(),
        period: "Week",
      },
      {
        label: "Last week",
        start: moment().subtract(1, "week").startOf("week").toISOString(),
        end: moment().subtract(1, "week").endOf("week").toISOString(),
        period: "Week",
      },
      {
        label: "This month",
        start: moment().startOf("month").toISOString(),
        end: moment().endOf("month").toISOString(),
        period: "Month",
      },
      {
        label: "Last month",
        start: moment().subtract(1, "month").startOf("month").toISOString(),
        end: moment().subtract(1, "month").endOf("month").toISOString(),
        period: "Month",
      },
      {
        label: "This year",
        start: moment().startOf("year").toISOString(),
        end: moment().endOf("year").toISOString(),
        period: "Year",
      },
      {
        label: "Last year",
        start: moment().subtract(1, "year").startOf("year").toISOString(),
        end: moment().subtract(1, "year").endOf("year").toISOString(),
        period: "Year",
      },
      {
        label: "All time",
        period: "All",
      },
    ],
  }),
  created() {
    //this.dates.push();
  },
});
